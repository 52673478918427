<template>
  <div>
    <a-card title="销售退货单详情">
      <a-button slot="extra" type="primary" style="margin-right: 8px" ghost v-print="'#printContent'">
        <a-icon type="printer" />打印</a-button
      >
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />返回</a-button
      >
      <section id="printContent">
        <a-spin :spinning="loading">
          <img id="barcode" style="float: right" />
          <a-descriptions bordered>
            <a-descriptions-item label="退货单号">
              {{ info.number }}
            </a-descriptions-item>
            <a-descriptions-item label="销售单号">
              {{ info.sales_order_number }}
            </a-descriptions-item>
            <a-descriptions-item label="客户">
              {{ info.client_name }}
            </a-descriptions-item>
            <a-descriptions-item label="制单人">
              {{ info.handler_name }}
            </a-descriptions-item>
            <a-descriptions-item label="制单日期">
              {{ info.handle_time }}
            </a-descriptions-item>
            <a-descriptions-item label="状态">
              {{ info.status_display }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ info.remark }}
            </a-descriptions-item>
            <a-descriptions-item label="附件">
              <div v-for="fileItem in info.sales_return_file_items" :key="fileItem.id">
                <a :href="fileItem.file" target="_blank" style="margin-right: 16px">{{ fileItem.name }}</a>
              </div>
            </a-descriptions-item>
          </a-descriptions>
          <a-divider orientation="left" style="margin-top: 30px">产品信息</a-divider>
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="info.sales_return_goods_items"
            :pagination="false"
          />
        </a-spin>
      </section>
    </a-card>
  </div>
</template>

<script>
import { saleReturnOrderDetail } from "@/api/sale";
import JsBarcode from "jsbarcode";
import NP from "number-precision";

export default {
  data() {
    return {
      loading: false,
      materialLoading: false,
      receiptOrder: undefined,
      info: {},
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "产品名称",
          dataIndex: "goods_name",
          key: "goods_name",
          width: 150,
        },
        {
          title: "产品编号",
          dataIndex: "goods_number",
          key: "goods_number",
          width: 150,
        },
        {
          title: "产品条码",
          dataIndex: "goods_barcode",
          key: "goods_barcode",
        },
        {
          title: "规格",
          dataIndex: "goods_spec",
          key: "goods_spec",
        },

        {
          title: "退货数量",
          dataIndex: "return_quantity",
          key: "return_quantity",
          width: 120,
        },
        {
          title: "退货单价(元)",
          dataIndex: "return_price",
          key: "return_price",
          width: 120,
          customRender: (value) => (value ? NP.round(value, 2).toFixed(2) : ""),
        },
        {
          title: "单位",
          dataIndex: "unit_name",
          key: "unit_name",
          width: 80,
        },
        {
          title: "金额(元)",
          dataIndex: "totalAmount",
          key: "totalAmount",
          width: 200,
          customRender: (value, item) => {
            if (item.isTotal) return value;
            value = NP.times(item.return_quantity, item.return_price);
            return item.id ? NP.round(value, 2).toFixed(2) : "";
          },
        },
      ],
      columnsAccount: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "结算账户",
          dataIndex: "account_name",
          key: "account_name",
          width: 200,
        },
        {
          title: "付款金额",
          dataIndex: "payment_amount",
          key: "payment_amount",
          width: 200,
        },
      ],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    getJsBarcode(number) {
      JsBarcode("#barcode", number, {
        lineColor: "#000",
        width: 2,
        height: 40,
        displayValue: true,
      });
    },
    initData() {
      this.loading = true;
      saleReturnOrderDetail({ id: this.$route.query.id })
        .then((data) => {
          this.info = data;
          this.info.sales_return_account_items = [
            ...this.info.sales_return_account_items,
            {
              id: "-1",
              isTotal: true,
              payment_amount: this.info.payment_amount,
            },
          ];
          this.info.sales_return_goods_items = [
            ...this.info.sales_return_goods_items,
            {
              id: "-1",
              isTotal: true,
              return_quantity: this.info.total_quantity,
              totalAmount: this.info.total_amount,
            },
          ];
          this.getJsBarcode(data.number);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style></style>
